<script>
	const socials = [
		{
			"name": "github",
			"url": "https://github.com/cattyngmd",
		},
		{
			"name": "youtube",
			"url": "https://youtube.com/c/cattyngmd",
		},
		{
			"name": "discord",
			"url": "",
		}
	]
	const phrase = "cattyn";
	const symbols = "410\\_.*&:?="

	$: current = "";
	let backward = -1;
	let counter = 0;
	let transition = false;

	function clamp(a,b,c) {
		if (a < b) return b;
		if (a > c) return c;
		return a;
	}

	function rand(min,max) {
		return Math.random() * Math.abs(max - min) + min
	}

	function play() {
		let audio = new Audio('./mem.mp3');
		audio.play();
	}

	setInterval(() => {
		counter = counter + backward;
		if(transition && counter < phrase.length * 2 && counter > 0) {
			current = phrase.substring(0, clamp(Math.floor(counter / 2), 0, phrase.length)) + symbols.charAt(rand(0, symbols.length));
			transition = false;
		} else {
			current = phrase.substring(0, clamp(Math.floor(counter / 2), 0, phrase.length));
			transition = true;
		}
		if (counter >= phrase.length * 2 + 4 || counter < -4) backward = backward * -1;
	}, 200);

</script>

<main>
	<h2 class="textable">{current} :^)</h2>
	<p>
		{#each socials as {name, url}}
				<a href="{url}" target="_blank" class="textable link"> {name} </a>
		{/each}
	</p>
	<input type="submit" style="color: transparent; background-color: transparent; border-color: transparent; cursor: default;" on:click={play}>
</main>

<style>
	:global(body) {
		background-color: #0b0b0b!important;
	}

	h2 {
		font-weight: lighter;
	}

	.textable {
		user-select: none;
		color: #333;
		transition-duration: 0.5s;
		transition: letter-spacing ease 0.5s, color ease 0.5s;
	}

	.textable:hover {
		letter-spacing: 2px;
		color: #929191;
    	transition: letter-spacing ease 0.5s, color ease 0.5s;
	}

	.link:hover {
		color: #bd87b8;
	}

	a {
		text-decoration: none;
	}

	main {
		text-align: center;
		padding: 6em;
		max-width: 240px;
		margin: 0 auto;
	}
</style>